@import 'src/styles/variables';

.footer {
  padding: 32px 0 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 32px 0 8px 0;
  font-size: 14px;

  @media (max-width: 560px) {
    font-size: 11px;
  }

  &-left, &-middle, &-right {
    flex: 1 1 33%;
  }

  &-left {
    margin-top: auto;
    padding-left: 16px;
    text-align: left;
    max-width: 298px;
  }

  &-right {
    margin-top: auto;
    padding-right: 16px;
    text-align: right;
    max-width: 311px;
  }

  &-middle {
    display: flex;
    flex-direction: column;

    .footer-sergio {
      font-weight: 600;
      font-size: 16px;
      padding-bottom: 10px;
    }

    .footer-email {
      font-weight: 600;
      font-size: 16px;
      padding-bottom: 16px;
    }

    .footer-copyright {
      padding-bottom: 16px;
      img {
        width: 30px;
        height: 30px;
      }
    }

    .footer-sizer {
      flex: 1 1 auto;
    }

    .footer-helpers {
      font-size: 12px;
      padding-bottom: 32px;
    }


  }


  .social-button {
    background-color: $color-green-light;
    color: $color-white;
  }


  .action-button {
    background-color: $color-pink;
    border: 2px solid $color-black;
  }

  &-button {
    padding-bottom: 16px;
  }


}