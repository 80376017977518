
$color-1: #FCECDD;
$color-2: #FFC288;
$color-3: #FF8474;
$color-4: #9F5F80;
$color-5: #583D72; 
$color-6: #2F2E41;
$color-7: #FEA82F;
$color-8: #FF6701;
$color-9: #f3d9ca;

$color-purple: #7635d9;
$color-pink: #ff0b84;
$color-green-dark: #08422a;
$color-green-light: #05A251;
$color-yellow: #ffcf27;
$color-black: #000000;
$color-black-almost: #0d0d0d;
$color-white: #ffffff;


$color-background: $color-white;
$color-title: $color-black-almost;
$color-line-light: $color-3;
$section-background-alternative: $color-9;
