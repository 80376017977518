@import '/src/styles/variables.scss';

.header {
  display: flex;
  flex-direction: row;
  
  &.section {
    background-color: $color-white;
    color: $color-black-almost;
    align-items: flex-start;
  }
  
  &-middle {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 8px;
    
    .header-image img {
      max-width: 420px; //calc(40vw + 32px);
      @media (max-width: 560px) {
        max-width: calc(100vw - 64px);
      }
    }
    
  }
  
  &-left, &-right {
    flex: 0 0 0;
    display: flex;
    flex-direction: column;
    // align items to bottom
    justify-content: flex-end;
    align-items: center;
    padding: 8px;
    img {
      max-width: calc(30vw - 32px);
      max-width: 300px;
      width: calc(30vw - 32px);
    }
  }

  @media (max-width: 560px) {
    // hide left and right
    &-left, &-right {
      display: none;
    }
  }

  &-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &-explanation {
    font-size: 1.2rem;
    padding: 32px 0;
  }
}