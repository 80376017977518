@import 'src/styles/variables';

.about {
  padding: 32px 0 64px 0;
  &.section {
    
  }

  .section-wrapper {
    max-width: 1200px;
  }
  

  .section-content {
    padding: 0;
  }
  
  .section-title {
    max-height: 48px;
    img {
      max-height: 48px;
    }
    padding-bottom: 16px;
  }
  
  
  
  &-text {
    text-align: left;
    @media (max-width: 700px) {
      padding: 0 16px;
    }
    a {
      font-weight: 600;
    }
  }
  
  
  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 560px) { 
        flex-direction: column;
    }
  }
  
  &-left {
    flex: 1 1 50%;
    padding: 0 32px 0 0;
    @media (max-width: 700px) {
      padding: 0;
      display: none;
    }
  }
  
  &-image {
    width: 300px;
    min-width: 300px;
    padding: 0 0 0 32px;
    @media (max-width: 700px) {
      padding: 16px 0 0 0;
      max-width: 200px;
      min-width: 200px;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
  
  &-share {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 32px 0;
    gap: 8px;
    width: 100%;
  }

}