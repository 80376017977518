@import '/src/styles/variables.scss';

.chat {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  
  &.section {
    color: $color-black-almost;
    align-items: center;
  }

  &-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &-explanation {
    font-size: 1.2rem;
    padding: 4px 0;
  }
  
  &-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    min-height: 300px
  }
  
  iframe {
    border: 0;
    //width: 100%;
    height: 100%;
    max-height: 100vh;
    min-height: 590px;
    min-width: 330px;
    width: 100vw;
  }
  
  .chat-zine-whats {
    padding-top: 40px;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      transition: transform 0.5s ease;

    }
  }

}