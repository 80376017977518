@import 'src/styles/variables';

.postadas {
  padding: 32px 0 0 0;

  &.section {
    
  }

  &-header {
    font-size: 18px;
    font-weight: 600;

    img {
      max-width: 250px;
      width: 250px;
    }
  }


  &-content {
    max-width: 940px;
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }

  &-postada {
    max-width: 300px;
    min-width: 200px;
    width: calc(100vw / 3 - 16px);
    height: 100%;
    
    img {
      max-width: 300px;
      width: 100%;
    }
    
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
        transition: transform 0.5s ease;
      
    }
    
  }
}




