@import 'src/styles/variables';

.video {
  &.section {
    padding-top: 40px;
    padding-bottom: 40px;
    
  }
  
  &-title img {
    max-width: 250px;
    width: 250px;
  }
  
  &-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }
  
  &-player {
    max-width: calc(100vw - 20px);
    min-width: 330px;
    width: calc(100vw - 55vw);
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .video-1 {
      
    }
    
    &-header {
      max-height: 40px;
      text-align: left;
      margin-bottom: 16px;
      img {
        max-height: 40px;
      }
    }
  }
  
  iframe {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    aspect-ratio: 16/9;
  }
  
}
