.playlist {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  align-items: center;
  justify-content: center;
  
  &-frame {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 1173px;
    max-height: 668px;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 60px 40px 40px 40px;
  }
  
  &-content {
    flex: 1 1 auto;
    max-width: 820px;
    max-height: 463px;
    aspect-ratio: 413/235;
    width: 100%;
    display: flex;
    padding: 80px 20px 20px 20px;
    
    .playlist-iframe {
      width: 100%;
      max-width: calc(100vw - 100px);
      margin: 0 auto;
      //margin-top: 40px;
      aspect-ratio: 820/464;
    }
  }
}

@media only screen and (max-device-width: 480px) {
  .playlist-frame {
    width: 70%;    
  }
  .playlist-iframe {
    min-height: 300px;
  }

  playlist-content {
    padding: 40px 20px 20px 20px;
  }
}